import { Controller } from "react-hook-form";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { SwitchLabel } from "src/FSD/shared/uiKit/v2/SwitchLabel";
import { SelectSpecialization } from "src/FSD/features/v2/SelectSpecialization";
import { useCreateFreeTask } from "../lib/hooks";

export const CreateFreeTaskForm = () => {
  const {
    control,
    handleSubmit,
    onSubmit,
    register,
    setValue,
    errors,
    selectOptions,
    newTaskPending,
    isSubmitting,
  } = useCreateFreeTask();

  return (
    <Modal.Layout>
      <Modal.Header>Создание задачи</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="project"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
                setValue("executor", null);
              }}
              isLoading={newTaskPending.getProjects}
              label="Проект"
              options={selectOptions.projects}
              error={Boolean(errors.project)}
              helperText={errors.project?.message}
            />
          )}
        />
        <TextField
          {...register("content")}
          label="Текст задачи"
          size={"small"}
          multiline
          minRows={3}
          maxRows={12}
          error={Boolean(errors.content)}
          helperText={errors.content?.message}
        />
        <Controller
          name="partGroup"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectSpecialization
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
                setValue("executor", null);
              }}
              error={Boolean(errors.partGroup)}
              helperText={errors.partGroup?.message}
            />
          )}
        />
        <Controller
          name="executor"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              key={value?.id ?? null}
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
              }}
              isLoading={newTaskPending.getUsersList}
              label="Исполнитель"
              options={selectOptions.users}
              error={Boolean(errors.executor)}
              helperText={errors.executor?.message}
            />
          )}
        />
        <Controller
          name="dateDeadline"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DateField
              label="Контрольный срок"
              changeDateHandler={(date) => {
                onChange(date);
              }}
              startDateProp={value}
              error={Boolean(errors.dateDeadline)}
              helperText={errors.dateDeadline?.message}
            />
          )}
        />
        <Controller
          name="importance"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SwitchLabel
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
              label="Важная задача"
            />
          )}
        />
        <Modal.Controls>
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Создать
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
