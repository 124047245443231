import {
  httpRequestGet,
  httpRequestPost,
  httpRequestPostMultiData,
  httpRequestPut,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessPause = (
  id: number,
  pause: boolean
): Promise<Response> => {
  // приостановить
  return httpRequestPost(
    `${config.localDomain}/v1/project-process/pause?id=${id}`,
    {},
    {
      paused: pause,
    }
  );
};

export const ApiProjectProcessGetStopped = (id: number): Promise<Response> => {
  // получение всех стопов
  return httpRequestGet(
    `${config.localDomain}/v1/task/get-stopped?processId=${id}`
  );
};

export const ApiProjectProcessCreateStop = (
  id: number,
  data: { content: string }
): Promise<Response> => {
  // создать стоп
  return httpRequestPost(
    `${config.localDomain}/v1/task/create-stop?processId=${id}`,
    {},
    data
  );
};

export const ApiProjectProcessDeposit = (
  id: number,
  data: { value: number }
): Promise<Response> => {
  // пополнить счет
  return httpRequestPost(
    `${config.localDomain}/v1/project-process/freeze-funds?id=${id}`,
    {},
    data
  );
};

export const ApiGetExecutorBankAccount = (userTypeId: string | number) => {
  return httpRequestGet(
    `${config.localDomain}/v1/bank-account/accounts-by-usertype?user_type_id=${userTypeId}`
  );
};

export const ApiProjectProcessRequest = (
  processId: number,
  data: { value: number }
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-access/request?processId=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessRequestTask = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-process/request-out-task?id=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessUnpublish = (id: number): Promise<Response> => {
  // вернуть с публикации
  return httpRequestPut(
    `${config.localDomain}/v1/project-process/unpublish?id=${id}`
  );
};

export const ApiProjectProcessCreateWork = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/process-work/create?processId=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessCreateTask = (
  processId: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/project-process/create-out-task?id=${processId}`,
    {},
    formData
  );
};

export const ApiProjectProcessRequestPreliminary = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-process/start-request-customer-align?id=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessDonePreliminary = (
  processId: number,
  data: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/project-process/start-customer-align?id=${processId}`,
    {},
    data
  );
};

export const ApiProjectProcessToProcess = (
  processId: number,
  projectId: number,
  all: number | string
): Promise<Response> => {
  // получить типы заданий для модалки сформировать задание
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/to-processes?processId=${processId}&projectId=${projectId}&all=${all}`
  );
};

export const ApiProjectProcessTaskComplete = (
  taskId: number
): Promise<Response> => {
  // причина выполнена в кнопке стоп
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    {
      CompleteForm: {
        status: 3,
      },
    }
  );
};

export const ApiProjectProcessTaskBackToWork = (
  taskId: number
): Promise<Response> => {
  // причину вернуть в кнопке стоп
  return httpRequestPost(
    `${config.localDomain}/v1/task/back-to-work?id=${taskId}`
  );
};
