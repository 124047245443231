import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import * as Yup from "yup";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  fetchPublishProcess,
  TValuesData,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import styles from "./ModalBackToWork.module.scss";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";
import { ApiPrimaryProjectProcess } from "../../../../../../types/api/primaryTypes/apiPrimaryProjectProcess";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";
import { ApiPrimaryProjectType } from "../../../../../../types/api/primaryTypes/apiPrimaryProjectType";

interface IModalBackToWorkProps {
  node: TValuesData | ApiPrimaryProjectProcess;
  project: ProjectViewData | ApiPrimaryProjectType;
  closeModal: () => void;
}

type TInputs = {
  date_start: any;
  date_limit: any;
  saveStatus: number;
};

const parseDate = (value: Date, originalValue: string) =>
  moment(originalValue).isValid()
    ? value
    : moment(originalValue, "DD.MM.YYYY").toDate();

const schema = Yup.object().shape({
  date_start: Yup.date()
    .typeError("Необходимо заполнить «Начальный срок»")
    .required("Необходимо заполнить «Начальный срок»"),
  date_limit: Yup.date()
    .typeError("Необходимо заполнить «Конечный срок»")
    .required("Необходимо заполнить «Конечный срок»")
    .transform(parseDate)
    .min(Yup.ref("date_start"), "Конечный срок не может быть раньше начального")
    .min(new Date(), "Конечный срок должен быть в будущем")
    .test(
      "minDay",
      "Продолжительность работ должна быть от 8 дней с учетом проверки результата у ГИП и смежных исполнителей",
      // @ts-ignore
      function (value) {
        const expiryDate = moment(value);
        const enteredDate = moment(this.parent.date_start);
        const tmpExpiryDate = moment(enteredDate).add(8, "days");
        if (!tmpExpiryDate.isAfter(expiryDate)) {
          return true;
        }
      }
    ),
});

export const ModalBackToWork = memo(
  ({ node, project, closeModal }: IModalBackToWorkProps) => {
    const { error422, publish } = useSelector(getProjectSections);
    const { id, date_limit, date_start, projectPart } = node;
    const dispatch = useDispatch();
    const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
    const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

    const {
      handleSubmit,
      control,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        date_start: start,
        date_limit: end,
      },
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        executor_type: [1, 2, 3, 4],
        save: dataValues.saveStatus,
      };
      await dispatch(fetchPublishProcess({ id, data }));
    };

    useEffect(() => {
      if (publish) {
        clearErrors();
        closeModal();
      }
    }, [publish]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    const dateRender = (
      <div className={styles.form_date}>
        <div className={styles.form_date_start}>
          <Controller
            control={control}
            name="date_start"
            render={({ field: { value } }) => (
              <DateField
                label="Начальный срок"
                changeDateHandler={(date) => {
                  setValue("date_start", date);
                }}
                startDateProp={value}
                error={!!errors.date_start}
              />
            )}
          />
          {errors.date_start && (
            <p className={styles.error}>{errors.date_start.message}</p>
          )}
        </div>
        <div className={styles.form_date_end}>
          <Controller
            control={control}
            name="date_limit"
            render={({ field: { value } }) => (
              <DateField
                variant={styles.formElement}
                label="Конечный срок"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                startDateProp={value}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={styles.error}>{errors.date_limit.message}</p>
          )}
        </div>
      </div>
    );

    return (
      <div className={styles.modal}>
        <h1>
          Публикация раздела «{projectPart.name}» по проекту «{project.name}»
        </h1>
        <form
          onSubmit={handleSubmit(formSubmitHandler)}
          className={styles.form}
        >
          {dateRender}
          <div className={styles.form_submit}>
            <FormControl className={styles.form_submit_saveBtn}>
              <Button
                variant="outlined"
                type="submit"
                onClick={() => setValue("saveStatus", 1)}
              >
                Сохранить в черновике
              </Button>
            </FormControl>
            <FormControl>
              <Button type="submit" onClick={() => setValue("saveStatus", 0)}>
                Взять в работу
              </Button>
            </FormControl>
          </div>
        </form>
      </div>
    );
  }
);
