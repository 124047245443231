import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  fetchTaskComplete,
  ProjectCompareGipReturn,
} from "../../../../../app/feature/ProjectView/CompareReducer/thunks";
import { config } from "../../../../../app.cofig";
import styles from "./style.module.scss";
import { NewAvatar, ThemeAvatar } from "../../../../newUI/NewAvatar/NewAvatar";
import { StarRating } from "../../../../newUI/SVG";
import { helpersYears } from "../../../../../utils/helpersYears";
import { trimPrice } from "../../../../../utils/trimNumber";
import { getProjectCompareGip } from "../../../../../app/feature/ProjectView/CompareReducer/projectCompareGip";

type CompareGipCardProps = {
  userGip: ProjectCompareGipReturn;
};

export const CompareGipCard: React.FC<CompareGipCardProps> = memo(
  ({ userGip }) => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const { isLoad } = useSelector(getProjectCompareGip);

    const fio = formFullUserName(userGip.user);

    const comment = `Комментарий: ${userGip.task.content.length > 0 ? userGip.task.content : "-"}`;

    const clickButton = async () => {
      await dispatch(fetchTaskComplete(userGip.task_id));
      window.location.href = `${config.localDomain}/project/test/${params.id}?tab=info`;
    };

    const experienceYears = helpersYears(
      Math.floor(userGip?.user.job_duration / 365)
    );

    return (
      <div className={styles.card}>
        <div className={styles.content}>
          <NewAvatar
            photoUrl={`${config.localDomain}${userGip.user.photo}`}
            theme={ThemeAvatar.NAME}
            fio={fio}
            userType={userGip.userType.listName}
            classname={styles.avatar}
          />
          <div className={styles.skills}>
            <div className={styles.star}>
              <p>{userGip.user.rating.gip}</p>
              <StarRating />
            </div>
            <p>Опыт {experienceYears}</p>
          </div>
          <div className={styles.price}>
            <p>{trimPrice(userGip.price)}₽</p>
            <span>В том числе НДС</span>
          </div>
          <div className={styles.btn}>
            <Link to={`/chat/${userGip.room_id}`}>
              <Button variant="outlined">Чат</Button>
            </Link>
            <Button onClick={clickButton} disabled={isLoad}>
              Нанять
            </Button>
          </div>
        </div>
        <div className={styles.comment}>
          <p>{comment}</p>
        </div>
      </div>
    );
  }
);
